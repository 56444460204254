import { useEffect, useState, lazy, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Buttons from "../../component/UI/Buttons";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import axios from "axios";

import AddIcon from "@mui/icons-material/Add";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import addDays from "date-fns/addDays";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNextButton from "./SwiperNextButton";
import SwiperPrevButton from "./SwiperPrevButton";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const BsDot = lazy(() =>
  import("react-icons/bs").then((module) => ({ default: module.BsDot }))
);

const BsFillPersonFill = lazy(() =>
  import("react-icons/bs").then((module) => ({
    default: module.BsFillPersonFill,
  }))
);

const Edit = () => {
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  const md = useMediaQuery("(min-width: 901px) and (max-width: 1500px)");
  const navigate = useNavigate();
  const location = useLocation();
  const passedId = location.state.propertyId;
  const dotVisible = xs ? "false" : "true";
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [convertedData, setConvertedData] = useState(null);
  const [topAmenities, setTopAmenities] = useState([]);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const timeDifference =
    range[0]?.endDate.getTime() - range[0]?.startDate.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  const style = {
    position: "absolute",
    top: { xs: "50%", sm: "35%", md: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    height: { xs: "80vh", sm: "35vh", md: "80vh" },
    borderRadius: "1rem",
  };

  const [productDetails, setProductDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}api/get-destination?destination_id=` +
            passedId
        );
        setProductDetails(res?.data?.data);
        setTopAmenities(res?.data?.data?.top_amenities);
        if (res?.data?.data?.room_description?.length > 0) {
          if (Array.isArray(res?.data?.data?.room_description?.price)) {
            setConvertedData(res?.data?.data?.room_description?.price);
          } else {
            setConvertedData(res?.data?.data?.room_description);
          }
        } else {
          setConvertedData(res?.data?.data?.room);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // const formattedDates = date?.map(dateString => {
    const formattedDates = productDetails?.room_available_date?.dates?.map(
      (dateString) => {
        const parts = dateString.split("-");
        const year = parseInt(parts[2]);
        const month = parts[1];
        const day = parseInt(parts[0]);

        const dateObj = new Date(`${month} ${day}, ${year} 00:00:00`);
        dateObj.setHours(0);
        dateObj.setMinutes(0);
        dateObj.setSeconds(0);

        const formattedDateString = dateObj.toLocaleString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: "Asia/Kolkata", // Modify this to your desired timezone
        });

        return {
          title: "Reserved",
          allDay: true,
          start: new Date(formattedDateString),
          end: new Date(formattedDateString),
        };
      }
    );
    setEvents(formattedDates);
  }, [productDetails]);

  const bookedDates = productDetails?.room_available_date?.dates?.map(
    (date) => {
      // Convert "20-sep-2024" format to a JavaScript Date object
      return moment(date, "DD-MMM-YYYY")?.toDate();
    }
  );

  const isDateBooked = (date) => {
    // Check if the date is in the bookedDates array
    return bookedDates?.some(
      (bookedDate) => bookedDate?.getTime() === date?.getTime()
    );
  };
  const dayPropGetter = (date) => {
    const now = new Date();
    if (date < now.setHours(0, 0, 0, 0)) {
      return {
        style: {
          backgroundColor: "#f0f0f0", // Light grey background for past dates
        },
      };
    }

    if (isDateBooked(date)) {
      return {
        style: {
          backgroundColor: "#ffcccb", // Light red background for booked dates
        },
      };
    }

    return {};
  };

  function divideArrayIntoThreeParts(arr) {
    const totalLength = arr?.length;
    const partLength = Math?.floor(totalLength / 3);

    // Calculate the number of extra elements (those that can't be evenly divided)
    const remainder = totalLength % 3;

    // Define the three parts
    const part1 = arr?.slice(0, partLength + (remainder > 0 ? 1 : 0));
    const part2 = arr?.slice(
      part1?.length,
      part1?.length + partLength + (remainder > 1 ? 1 : 0)
    );
    const part3 = arr?.slice(part1?.length + part2?.length);

    return [part1, part2, part3];
  }

  const [part1, part2, part3] = divideArrayIntoThreeParts(
    productDetails?.amenities
  );

  const convertToMeters = (distance) => {
    const [value, unit] = distance.split(" ");

    if (parseFloat(value) === 0) {
      return 0; // Handle 0 distance
    }

    if (unit === "km") {
      return parseFloat(value) * 1000; // convert kilometers to meters
    } else if (unit === "m") {
      return parseFloat(value); // already in meters
    }
    return parseFloat(value); // Fallback in case of unknown units
  };

  const sortedPropertySurrounding = productDetails?.property_surronding?.sort(
    (a, b) => {
      return convertToMeters(a?.distance) - convertToMeters(b?.distance);
    }
  );

  const nearbyMidpoint = Math.ceil(sortedPropertySurrounding?.length / 2);

  const firstHalf = sortedPropertySurrounding?.slice(0, nearbyMidpoint);
  const secondHalf = sortedPropertySurrounding?.slice(nearbyMidpoint);

  const handleEditProperty = (step) => {
    const stepData = step;
    const propertyData = productDetails;

    navigate("/edit-property", {
      state: {
        step: stepData,
        propertyData: propertyData,
      },
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filterAmenitiesByRoom = (amenities, room_description) => {
    // console.log("roomDes:::",room_description)
    return (
      room_description &&
      room_description?.length > 0 &&
      room_description?.map((room) => {
        const filteredAmenities = amenities
          ?.map((amenity) => {
            const matchingSubamenities = amenity?.subamenities?.filter((sub) =>
              room?.amenitie_id?.includes(sub.id)
            );

            // If there are matching subamenities, return the main amenity with filtered subamenities
            if (matchingSubamenities?.length > 0) {
              return {
                ...amenity,
                subamenities: matchingSubamenities,
              };
            }
            return null; // or filter out this amenity if you don't want it included
          })
          .filter(Boolean); // Remove null values

        return {
          ...room,
          amenities: filteredAmenities,
        };
      })
    );
  };

  const filteredRooms = filterAmenitiesByRoom(
    productDetails?.amenities,
    // productDetails?.room_description
    convertedData
  );

  function renderRoomDetails(room) {
    return (
      <>
        {!md ? room.name : <Box>{room.name}</Box>}
        <span
          className="bed-description"
          dangerouslySetInnerHTML={{ __html: room.description }}
        />
      </>
    );
  }

  const [topAmenitiesArray1, setTopAmenitiesArray1] = useState([]);
  const [topAmenitiesArray2, setTopAmenitiesArray2] = useState([]);
  const [topAmenitiesArray3, setTopAmenitiesArray3] = useState([]);

  const structuredAmenities = () => {
    if (topAmenities.length > 0) {
      const length = topAmenities.length;
      const size = Math.ceil(length / 3);
      setTopAmenitiesArray1(topAmenities.slice(0, size));
    setTopAmenitiesArray2(topAmenities.slice(size, size * 2));
    setTopAmenitiesArray3(topAmenities.slice(size * 2));
    }
  };

  useEffect(() => {
    structuredAmenities();
  }, [topAmenities]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                top: ".1rem",
                right: ".1rem",
                zIndex: 3,
              }}
            >
              <HighlightOffRoundedIcon size="large" />
            </IconButton>
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                type: "fraction",
              }}
              modules={[Navigation, Pagination, Scrollbar, Autoplay]}
              spaceBetween={xs ? 5 : sm ? 5 : 30}
              slidesPerView={xs ? 1 : sm ? 1 : 1}
              loop={true}
              style={{
                "--swiper-pagination-color": "#FFBA08",
                "--swiper-pagination-bullet-inactive-color": "#999999",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-size": "10px",
                "--swiper-pagination-bullet-horizontal-gap": "7px",
                "--swiper-pagination-bottom": xs
                  ? "10px"
                  : sm
                  ? "10px"
                  : "-2rem",
              }}
            >
              {productDetails?.property_images?.map((ele, i) => (
                <SwiperSlide key={i}>
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: 0, sm: 0, md: 0 },
                      paddingTop: "60%",
                      paddingBottom: {
                        xs: "2rem",
                        sm: "2rem",
                        md: "5rem",
                      },
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <Box
                      component="img"
                      src={`https://server.voyagerstales.com/${ele.img}`}
                      alt="No Preview"
                      sx={{
                        width: { xs: "90%", sm: "100%", md: "auto" },
                        height: { xs: "80%", sm: "90%", md: "80%" }, // Responsive height
                        objectFit: "contain",
                        position: "absolute",
                        top: "2.5rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                </SwiperSlide>
              ))}
              <SwiperPrevButton />
              <SwiperNextButton />
            </Swiper>
          </Box>
        </Fade>
      </Modal>
      {/* Dashboard button */}
      <Box
        sx={{
          mb: "1rem",
        }}
      >
        <Buttons
          text="Go to Dashboard"
          buttonBorder="1px solid blue"
          textColor="blue"
          btnClick={() => navigate("/")}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          my: "1rem",
        }}
      >
        {/* Type of Property Section */}
        <Box
          sx={{ mb: "1rem", display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            <Typography
              fontWeight={600}
              sx={{
                color: "black",
                mb: ".5rem",
                fontSize: "1.2rem",
              }}
            >
              Type of Property
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "6.0625rem",
                justifyContent: "space-between",
                borderRadius: "0.9375rem",
                border: "1px solid #E7E7E7",
                p: { xs: ".5rem", sm: "1rem", md: "1rem" },
              }}
            >
              <Box
                sx={{
                  height: { xs: "3rem", sm: "2rem", md: "2rem" },
                  width: { xs: "3rem", sm: "2rem", md: "2rem" },
                }}
              >
                <img
                  src={productDetails?.property_type?.logo}
                  alt="No Preview"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../Assets/Images/no-preview.jpeg");
                  }}
                />
              </Box>
              <Typography>
                Property Type: {productDetails?.property_type?.name}
              </Typography>
              {/* <Typography>{localStorage.getItem("property_name")}</Typography> */}
            </Box>
          </Box>
          <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(0)} />
          </Box>
        </Box>

        {/*  About your property Section */}
        <Box
          sx={{ mb: "1rem", display: "flex", justifyContent: "space-between" }}
        >
          <Box
            sx={{
              width: "60%",
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                color: "Black",
                mb: ".5rem",
                fontSize: "1.2rem",
              }}
            >
              About your property
            </Typography>
            <Typography fontWeight={600} sx={{ fontSize: "1rem", mb: ".2rem" }}>
              {productDetails?.property_name} &nbsp;
              {/* {localStorage.getItem("property_name")} &nbsp; */}
              <Typography
                variant="caption"
                fontWeight={400}
                sx={{ color: "secondaryText.main", fontSize: "1rem" }}
              >
                {/* {`${getPropertyType()}`} */}(
                {productDetails?.property_type?.name})
              </Typography>
            </Typography>
            <Typography>
              {/* Come and stay in this superb duplex T2, in the heart of the
              historic center of Bordeaux. Spacious and bright, in a real
              Bordeaux building in exposed stone, you will enjoy all the charms
              of the city thanks to its ideal location. Close to many shops,
              bars and restaurants, you can access the apartment by tram A and C
              and bus routes 27 and 44 */}
              {productDetails?.place_description}
              {/* {localStorage.getItem("property_desc")} */}
            </Typography>
          </Box>
          <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(1)} />
          </Box>
        </Box>

        {/* Property Images section */}
        {productDetails?.property_images?.length > 0 && (
          <Box
            sx={{
              mb: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "60%" }}>
              <Typography
                fontWeight={600}
                sx={{
                  color: "Black",
                  mb: ".5rem",
                  fontSize: "1.2rem",
                }}
              >
                Property Images
              </Typography>

              <Box
                gap={2}
                sx={{
                  display: { xs: "none", sm: "flex", md: "flex" },
                  borderRadius: ".7rem",
                  overflow: "hidden",
                  height: "19.688rem",
                  position: "relative",
                  mb: "2rem",
                }}
              >
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  startIcon={
                    <Suspense>
                      <AddIcon />
                    </Suspense>
                  }
                  sx={{
                    position: "absolute",
                    right: "1rem",
                    bottom: "1rem",
                    backgroundColor: "white.main",
                    color: "black.main",
                    textTransform: "inherit",
                    px: 2,
                    zIndex: 1,
                    "&:hover": {
                      backgroundColor: "#e2ddd3",
                      color: "black.main",
                    },
                  }}
                >
                  {productDetails?.property_images?.length > 5
                    ? productDetails?.property_images?.length - 5
                    : 0}
                  Photos
                </Button>
                <Box
                  sx={{
                    width: "49%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  {/* changelog */}

                  <img
                    className="zoom"
                    // src={`${process.env.REACT_APP_API}${
                    //   productDetails?.property_images?.at(0)?.img
                    // }`}
                    src={`https://server.voyagerstales.com/${
                      productDetails?.property_images?.at(0)?.img
                    }`}
                    alt="No Preview"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "fill",
                      borderRadius: "8px",
                    }}
                    onError={(e) => {
                      e.onerror = null;
                      e.target.src = require("../../Assets/Images/no-preview.jpeg");
                    }}
                  />
                </Box>

                <Box
                  gap={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "49%",
                    // border: "1px solid green",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "47.5%",
                      height: "48%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="zoom"
                      // src={`https://server.voyagerstales.com/${
                      //   productDetails?.property_images?.at(1)?.img
                      // }`}
                      src={`https://server.voyagerstales.com/${
                        productDetails?.property_images?.at(1)?.img
                      }`}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                        borderRadius: "8px",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "47.5%",
                      height: "48%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="zoom"
                      // src={`https://server.voyagerstales.com/${
                      //   productDetails?.property_images?.at(2)?.img
                      // }`}
                      src={`https://server.voyagerstales.com/${
                        productDetails?.property_images?.at(2)?.img
                      }`}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                        borderRadius: "8px",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "47.5%",
                      height: "48%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="zoom"
                      // src={`${process.env.REACT_APP_API}${
                      //   productDetails?.property_images?.at(3)?.img
                      // }`}
                      src={`https://server.voyagerstales.com/${
                        productDetails?.property_images?.at(3)?.img
                      }`}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                        borderRadius: "8px",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "47.5%",
                      height: "48%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="zoom"
                      // src={`${process.env.REACT_APP_API}${
                      //   productDetails?.property_images?.at(4)?.img
                      // }`}
                      src={`https://server.voyagerstales.com/${
                        productDetails?.property_images?.at(4)?.img
                      }`}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                        borderRadius: "8px",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Buttons text="Edit" btnClick={() => handleEditProperty(5)} />
            </Box>
          </Box>
        )}

        {/* Your address with location Section */}
        <Box
          sx={{ mb: "1rem", display: "flex", justifyContent: "space-between" }}
        >
          <Box
            sx={{
              width: "60%",
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                color: "Black",
                mb: ".5rem",
                fontSize: "1.2rem",
              }}
            >
              Your address with location
            </Typography>
            <Typography
              fontWeight={700}
              sx={{ color: "black.main", fontSize: ".9rem" }}
            >
              {productDetails?.address?.[0]?.address},{/* 64 Place Napoléon */}
            </Typography>
            <Typography
              fontWeight={700}
              sx={{ color: "black.main", fontSize: ".9rem" }}
            >
              {productDetails?.address?.[0]?.city}
              {productDetails?.address?.[0]?.province !== "" ? ", " : ""}{" "}
              {productDetails?.address?.[0]?.province}
              {productDetails?.address?.[0]?.region !== "" ? ", " : ""}
              {productDetails?.address?.[0]?.region}
              {/* LAVAL, Pays de la Loire(PL), 53000 */}
            </Typography>

            <iframe
              src={`https://maps.google.com/maps?q=${productDetails?.address?.[0]?.lat},${productDetails?.address?.[0]?.long}&hl=es;&output=embed&z=15`}
              style={{ width: "100%", height: "30rem", border: "0" }}
              loading="lazy"
              allowFullScreen
            ></iframe>
          </Box>
          <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(2)} />
          </Box>

          {/* <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15257.123456789012!2d77.4166842!3d23.2136667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c71c70b5f872f%3A0xa33d9c7ad028b524!2sIndra%20Puri%2C%20Bhopal%2C%20Madhya%20Pradesh%2C%20India!5e0!3m2!1sen!2sus!4v1617234560504!5m2!1sen!2sus"
              style={{
                width: "100%",
                height: "20rem",
                border: "0px",
                // marginBottom: "3rem",
                marginTop: ".5rem",
              }}
              loading="lazy"
              allowfullscreen
            ></iframe> */}
        </Box>

        {/* Price Section */}
        <Box
          sx={{
            my: "2rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "60%",
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                color: "Black",
                mb: ".5rem",
                fontSize: "1.2rem",
              }}
            >
              Price Section{" "}
              <span
                style={{ fontWeight: "normal", fontSize: "12px" }}
              >{`(Incase of Whole Property, change the price from Host details section)`}</span>
            </Typography>
            {productDetails?.whole_property && productDetails?.rent_rooms ? (
              <>
                {/* Whole Property */}
                <Box>
                  <Table>
                    <TableHead
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        paddingY: ".5rem",
                        border: "1px solid #a9a9a9",
                        // position:"sticky",
                        // top:0,
                        // zIndex:2,
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                              },
                            }}
                          >
                            Room type
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                              },
                            }}
                          >
                            Number of guests
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                              },
                            }}
                          >
                            {`Price for 1 day`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "1rem",
                            paddingY: ".5rem",
                            borderBottom: "2px solid #a9a9a9",
                          }}
                        >
                          {filteredRooms?.map((ele, i) => (
                            <Box key={i}>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  // onClick={() => handleRowClick(ele)}
                                >
                                  {ele?.rooms_name}
                                </Typography>
                              </Box>
                              <Box>
                                {ele?.bed_type?.map((bed, bedIndex) => (
                                  <Box
                                    key={bedIndex}
                                    sx={{
                                      display: "flex",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {bed?.number} * &nbsp;
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {bed?.name}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "2px solid #a9a9a9",
                            borderLeft: "2px solid #a9a9a9",
                            padding: "0",
                            margin: "0",
                            // verticalAlign: "top",
                          }}
                        >
                          {productDetails?.whole_property_prices
                            ?.filter((amt) => amt?.price !== 0)
                            ?.map((amt, index) => (
                              <Box
                                key={index}
                                sx={{
                                  borderBottom:
                                    index !=
                                    productDetails?.whole_property_prices
                                      ?.length -
                                      1
                                      ? "2px solid #a9a9a9"
                                      : "", // Border for each row
                                  padding: ".5rem",
                                  height: "100px",
                                }}
                              >
                                <Typography>
                                  <BsFillPersonFill /> * {amt?.person}
                                </Typography>
                              </Box>
                            ))}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0",
                            borderBottom: "2px solid #a9a9a9",
                            borderLeft: "2px solid #a9a9a9",
                          }}
                        >
                          <Box>
                            {productDetails?.whole_property_prices
                              ?.filter((amt) => amt?.price !== 0)
                              ?.map((amt, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    borderBottom:
                                      index !=
                                      productDetails?.whole_property_prices
                                        ?.length -
                                        1
                                        ? "2px solid #a9a9a9"
                                        : "", // Border for each row
                                    padding: ".5rem",
                                    height: "100px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    fontWeight={400}
                                    sx={{
                                      fontSize: {
                                        xs: "12px",
                                        sm: "14px",
                                        md: "14px",
                                      },
                                      color: "gray",
                                    }}
                                  >
                                    €
                                    {amt?.price?.toFixed(0) *
                                      daysDifference?.toFixed(0)}
                                  </Typography>
                                </Box>
                              ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                {/* Rooms Property */}
                <Box
                  sx={{
                    mt: "2rem",
                  }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        paddingY: ".5rem",
                        border: "1px solid #a9a9a9",
                        // position:"sticky",
                        // top:0,
                        // zIndex:2,
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                              },
                            }}
                          >
                            Room type
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                              },
                            }}
                          >
                            Number of guests
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                              },
                            }}
                          >
                            Price for 1 day
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRooms?.map((ele, i) => (
                        <TableRow key={i}>
                          <TableCell
                            sx={{
                              padding: "1rem",
                              paddingY: ".5rem",
                              borderBottom: "2px solid #a9a9a9",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                // onClick={() => handleRowClick(ele)}
                              >
                                {ele?.rooms_name}
                              </Typography>
                            </Box>
                            <Box>
                              {ele?.bed_type?.map((bed, bedIndex) => (
                                <Box
                                  key={bedIndex}
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {bed?.number} * &nbsp;
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {bed?.name}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "2px solid #a9a9a9",
                              borderLeft: "2px solid #a9a9a9",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            {ele?.price
                              ?.filter((amt) => amt?.price !== 0)
                              ?.map((amt, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    borderBottom:
                                      index != ele?.price.length - 1
                                        ? "2px solid #a9a9a9"
                                        : "", // Border for each row
                                    padding: ".5rem",
                                    height: "100px",
                                  }}
                                >
                                  <Typography>
                                    <BsFillPersonFill /> * {amt?.person}
                                  </Typography>
                                </Box>
                              ))}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0",
                              borderBottom: "2px solid #a9a9a9",
                              borderLeft: "2px solid #a9a9a9",
                            }}
                          >
                            <Box>
                              {ele?.price
                                ?.filter((amt) => amt?.price !== 0)
                                ?.map((amt, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      borderBottom:
                                        index != ele?.price.length - 1
                                          ? "2px solid #a9a9a9"
                                          : "", // Border for each row
                                      padding: ".5rem",
                                      height: "100px",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      fontWeight={400}
                                      sx={{
                                        fontSize: {
                                          xs: "12px",
                                          sm: "14px",
                                          md: "14px",
                                        },
                                        color: "gray",
                                      }}
                                    >
                                      €
                                      {amt?.price?.toFixed(0) *
                                        daysDifference?.toFixed(0)}
                                    </Typography>
                                  </Box>
                                ))}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </>
            ) : (
              <Table
                sx={{
                  width: "100%",
                }}
              >
                <TableHead
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#f2f2f2",
                    padding: "1rem",
                    paddingY: ".5rem",
                    border: "1px solid #a9a9a9",
                    // position:"sticky",
                    // top:0,
                    // zIndex:2,
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "16px",
                          },
                        }}
                      >
                        Room type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "16px",
                          },
                        }}
                      >
                        Number of guests
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "16px",
                          },
                        }}
                      >
                        Price for 1 day
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productDetails?.room_description?.length > 0 ? (
                    <>
                      {productDetails?.whole_property ? (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "1rem",
                                paddingY: ".5rem",
                                borderBottom: "2px solid #a9a9a9",
                              }}
                            >
                              {filteredRooms?.map((ele, i) => (
                                <Box key={i}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                      // onClick={() => handleRowClick(ele)}
                                    >
                                      {ele?.rooms_name}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    {ele?.bed_type?.map((bed, bedIndex) => (
                                      <Box
                                        key={bedIndex}
                                        sx={{
                                          display: "flex",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {bed?.number} * &nbsp;
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {bed?.name}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              ))}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "2px solid #a9a9a9",
                                borderLeft: "2px solid #a9a9a9",
                                padding: "0",
                                margin: "0",
                                // verticalAlign: "top",
                              }}
                            >
                              {productDetails?.whole_property_prices
                                ?.filter((amt) => amt?.price !== 0)
                                ?.map((amt, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      borderBottom:
                                        index !==
                                        productDetails?.whole_property_prices
                                          ?.length -
                                          1
                                          ? "2px solid #a9a9a9"
                                          : "", // Border for each row
                                      padding: ".5rem",
                                      height: "100px",
                                    }}
                                  >
                                    <Typography>
                                      <BsFillPersonFill /> * {amt?.person}
                                    </Typography>
                                  </Box>
                                ))}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0",
                                borderBottom: "2px solid #a9a9a9",
                                borderLeft: "2px solid #a9a9a9",
                              }}
                            >
                              <Box>
                                {productDetails?.whole_property_prices
                                  ?.filter((amt) => amt?.price !== 0)
                                  ?.map((amt, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        borderBottom:
                                          index !==
                                          productDetails?.whole_property_prices
                                            ?.length -
                                            1
                                            ? "2px solid #a9a9a9"
                                            : "", // Border for each row
                                        padding: ".5rem",
                                        height: "100px",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        fontWeight={400}
                                        sx={{
                                          fontSize: {
                                            xs: "12px",
                                            sm: "14px",
                                            md: "14px",
                                          },
                                          color: "gray",
                                        }}
                                      >
                                        €
                                        {amt?.price?.toFixed(0) *
                                          daysDifference?.toFixed(0)}
                                      </Typography>
                                    </Box>
                                  ))}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          {filteredRooms?.map((ele, i) => (
                            <TableRow key={i}>
                              <TableCell
                                sx={{
                                  padding: "1rem",
                                  paddingY: ".5rem",
                                  borderBottom: "2px solid #a9a9a9",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    // onClick={() => handleRowClick(ele)}
                                  >
                                    {ele?.rooms_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  {ele?.bed_type?.map((bed, bedIndex) => (
                                    <Box
                                      key={bedIndex}
                                      sx={{
                                        display: "flex",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {bed?.number} * &nbsp;
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {bed?.name}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "2px solid #a9a9a9",
                                  borderLeft: "2px solid #a9a9a9",
                                  padding: "0",
                                  margin: "0",
                                }}
                              >
                                {ele?.price
                                  ?.filter((amt) => amt?.price !== 0)
                                  ?.map((amt, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        borderBottom:
                                          index != ele?.price.length - 1
                                            ? "2px solid #a9a9a9"
                                            : "", // Border for each row
                                        padding: ".5rem",
                                        height: "100px",
                                      }}
                                    >
                                      <Typography>
                                        <BsFillPersonFill /> * {amt?.person}
                                      </Typography>
                                    </Box>
                                  ))}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0",
                                  borderBottom: "2px solid #a9a9a9",
                                  borderLeft: "2px solid #a9a9a9",
                                }}
                              >
                                <Box>
                                  {ele?.price
                                    ?.filter((amt) => amt?.price !== 0)
                                    ?.map((amt, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          borderBottom:
                                            index != ele?.price.length - 1
                                              ? "2px solid #a9a9a9"
                                              : "", // Border for each row
                                          padding: ".5rem",
                                          height: "100px",
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          sx={{
                                            fontSize: {
                                              xs: "12px",
                                              sm: "14px",
                                              md: "14px",
                                            },
                                            color: "gray",
                                          }}
                                        >
                                          €
                                          {amt?.price?.toFixed(0) *
                                            daysDifference?.toFixed(0)}
                                        </Typography>
                                      </Box>
                                    ))}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {convertedData?.map((ele, i) => (
                        <TableRow key={i}>
                          <TableCell
                            sx={{
                              padding: "1rem",
                              paddingY: ".5rem",
                              borderBottom: "2px solid #a9a9a9",
                            }}
                          >
                            <Typography
                              component="div"
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  md: "14px",
                                },
                              }}
                            >
                              {renderRoomDetails(ele)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "1rem",
                              paddingY: ".5rem",
                              borderBottom: "2px solid #a9a9a9",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  md: "14px",
                                },
                              }}
                            >
                              {`${ele?.guests} people`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "1rem",
                              paddingY: ".5rem",
                              borderBottom: "2px solid #a9a9a9",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                fontWeight={400}
                                sx={{
                                  fontSize: {
                                    xs: "12px",
                                    sm: "14px",
                                    md: "14px",
                                  },
                                  color: "gray",
                                }}
                              >
                                €
                                {ele?.price?.toFixed(0) *
                                  daysDifference?.toFixed(0)}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
          <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(7)} />
          </Box>
        </Box>

        {/* Host Details */}
        <Box
          sx={{ mb: "1rem", display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            <Typography
              fontWeight={600}
              sx={{
                color: "Black",
                mb: ".5rem",
                fontSize: "1.2rem",
              }}
            >
              Host Details
            </Typography>
            <Typography>
              Name:{" "}
              <span style={{ fontWeight: "bold" }}>
                {productDetails?.host?.host_name}
              </span>
            </Typography>
            <Typography>
              Language:{" "}
              <span style={{ fontWeight: "bold" }}>
                {productDetails?.host?.Languages}
              </span>
            </Typography>
            {productDetails?.staff_language && (
              <Typography>
                Staff Language:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {productDetails?.staff_language}
                </span>
              </Typography>
            )}
            <Typography>
              Offer Breakfast:{" "}
              <span style={{ fontWeight: "bold" }}>
                {productDetails?.offer_breakfast === false ? "No" : "Yes"}
              </span>
            </Typography>
            <Typography>
              Parking Facility:{" "}
              <span style={{ fontWeight: "bold" }}>
                {productDetails?.parking === false ? "No" : "Yes"}
              </span>
            </Typography>
          </Box>
          <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(6)} />
          </Box>
        </Box>

        {/*  Property layout Section */}
        <Box
          sx={{ mb: "1rem", display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            <Typography
              fontWeight={600}
              sx={{
                color: "Black",
                mb: ".5rem",
                fontSize: "1.2rem",
              }}
            >
              Property layout
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "row", md: "row" },
                alignItems: { xs: "start", sm: "center", md: "center" },
                flexWrap: { xs: "wrap", sm: "nowrap", md: "nowrap" },
              }}
            >
              <Typography
                fontWeight={400}
                variant="body2"
                sx={{
                  fontSize: { xs: ".9rem", sm: "1.2rem", md: "1.2rem" },
                  lineHeight: "1.25rem",
                }}
              >
                {productDetails?.total_guests &&
                  `${productDetails?.total_guests} guests`}
                {/* {`${localStorage.getItem("max_guest")} guests`} */}

                {/* 2 guests */}
              </Typography>
              {productDetails?.room?.length > 0 && (
                <Suspense>
                  <BsDot
                    style={{ display: dotVisible ? "inline-block" : "none" }}
                  />
                </Suspense>
              )}

              <Typography
                fontWeight={400}
                variant="body2"
                sx={{
                  fontSize: { xs: ".9rem", sm: "1.2rem", md: "1.2rem" },
                  lineHeight: "1.25rem",
                }}
              >
                {productDetails?.room?.length > 0 &&
                  `${productDetails?.room?.length} bedroom`}
                {/* {`${localStorage.getItem("all_rooms")} bedroom`} */}
                {/* 1 bedroom */}
              </Typography>
              {productDetails?.bathrooms && (
                <Suspense>
                  <BsDot />
                </Suspense>
              )}

              {/* <Typography
                fontWeight={400}
                variant="body2"
                sx={{
                  fontSize: {xs:".9rem", sm:"1.2rem", md:"1.2rem"},
                  lineHeight: "1.25rem",
                }}
              >
                {`${previewData?.beds} bed`}
                
              </Typography>
              <BsDot /> */}
              {productDetails?.bathrooms > 0 && (
                <Typography
                  fontWeight={400}
                  variant="body2"
                  sx={{
                    fontSize: { xs: ".9rem", sm: "1.2rem", md: "1.2rem" },
                    lineHeight: "1.25rem",
                  }}
                >
                  {`${productDetails?.bathrooms} ${
                    productDetails?.bathrooms > 1 ? "bathrooms" : "bathroom"
                  }`}
                </Typography>
              )}

              {productDetails?.kitchen > 0 && (
                <Suspense>
                  <BsDot />
                </Suspense>
              )}
              {productDetails?.kitche > 0 && (
                <Typography
                  fontWeight={400}
                  variant="body2"
                  sx={{
                    fontSize: { xs: ".9rem", sm: "1.2rem", md: "1.2rem" },
                    lineHeight: "1.25rem",
                  }}
                >
                  {`${productDetails?.kitchen} kitchen`}
                  {/* {`${localStorage.getItem("kitchen")} kitchen`} */}
                  {/* 1 bath */}
                </Typography>
              )}
            </Box>
          </Box>
          {/* <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(7)} />
          </Box> */}
        </Box>

        {/* Amenities Section*/}
        {productDetails?.amenities?.length > 0 && (
          <Box
            sx={{
              width: "100%",
              mb: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "90%",
              }}
            >
              <Typography
                fontWeight={600}
                sx={{
                  color: "Black",
                  mb: ".5rem",
                  fontSize: "1.2rem",
                }}
              >
                Top Amenities
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {topAmenitiesArray1.length > 0 &&
                    topAmenitiesArray1?.map((item, index) => (
                      <Box
                        key={{ index }}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px", // Adjust the padding of the label
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {topAmenitiesArray2.length > 0 &&
                    topAmenitiesArray2?.map((item, index) => (
                      <Box
                        key={{ index }}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px", // Adjust the padding of the label
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {topAmenitiesArray3?.length > 0 &&
                    topAmenitiesArray3?.map((item, index) => (
                      <Box
                        key={{ index }}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px", // Adjust the padding of the label
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
              <Typography
                fontWeight={600}
                sx={{
                  color: "Black",
                  mb: ".5rem",
                  fontSize: "1.2rem",
                }}
              >
                Amenities
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {productDetails?.amenities?.length > 0 &&
                    part1?.map((ele, i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele?.name}
                        </Typography>
                        {ele?.subamenities && ele?.subamenities?.length > 0 && (
                          <Box>
                            {ele?.subamenities?.map((subAmenity, subIndex) => (
                              <Typography
                                key={subIndex}
                                sx={{
                                  fontSize: "14px", // Adjust the padding of the label
                                }}
                              >
                                {subAmenity?.name}
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {productDetails?.amenities?.length > 0 &&
                    part2?.map((ele, i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele?.name}
                        </Typography>
                        {ele?.subamenities && ele?.subamenities?.length > 0 && (
                          <Box>
                            {ele?.subamenities?.map((subAmenity, subIndex) => (
                              <Typography
                                key={subIndex}
                                sx={{
                                  fontSize: "14px", // Adjust the padding of the label
                                }}
                              >
                                {subAmenity?.name}
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                  }}
                >
                  {productDetails?.amenities?.length > 0 &&
                    part3?.map((ele, i) => (
                      <Box
                        key={i}
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele?.name}
                        </Typography>
                        {ele?.subamenities && ele?.subamenities?.length > 0 && (
                          <Box>
                            {ele?.subamenities?.map((subAmenity, subIndex) => (
                              <Typography
                                key={subIndex}
                                sx={{
                                  fontSize: "14px", // Adjust the padding of the label
                                }}
                              >
                                {subAmenity?.name}
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
            <Box>
              <Buttons text="Edit" btnClick={() => handleEditProperty(3)} />
            </Box>
          </Box>
        )}

        {/* House Rules section */}
        {(productDetails?.house_rule?.length > 0 ||
          productDetails?.check_in_time ||
          productDetails?.check_in_time_until ||
          productDetails?.check_out_time ||
          productDetails?.check_out_time_until) && (
          <Box
            sx={{
              mb: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                fontWeight={600}
                sx={{
                  color: "Black",
                  mb: ".5rem",
                  fontSize: "1.2rem",
                }}
              >
                House Rules
              </Typography>
              {productDetails?.house_rule?.length > 0 &&
                productDetails?.house_rule?.map((ele, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: "1rem",
                    }}
                  >
                    <Box>
                      <img
                        src={`${process.env.REACT_APP_API}${ele.img}`}
                        alt="No Preview"
                        style={{
                          height: "2rem",
                          width: "2rem",
                          objectFit: "fill",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("../../Assets/Images/no-preview.jpeg");
                        }}
                      />
                    </Box>
                    <Typography
                      fontWeight={400}
                      variant="body2"
                      sx={{
                        fontSize: "1rem",
                        letterSpacing: "-0.019rem",
                        lineHeight: "1.5rem",
                        mt: 0.3,
                        ml: 2,
                      }}
                    >
                      {ele?.rule}
                    </Typography>
                  </Box>
                ))}
              {productDetails?.check_in_time && (
                <Typography
                  sx={{
                    mt: "1rem",
                  }}
                >
                  Check-in Time:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {productDetails?.check_in_time}
                  </span>
                </Typography>
              )}
              {productDetails?.check_in_time_until && (
                <Typography
                  sx={{
                    mt: ".5rem",
                  }}
                >
                  Check-in Until:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {productDetails?.check_in_time_until}
                  </span>
                </Typography>
              )}
              {productDetails?.check_out_time && (
                <Typography
                  sx={{
                    mt: ".5rem",
                  }}
                >
                  Check-out Time:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {productDetails?.check_out_time}
                  </span>
                </Typography>
              )}
              {productDetails?.check_out_time_until && (
                <Typography
                  sx={{
                    mt: ".5rem",
                  }}
                >
                  Check-out Until:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {productDetails?.check_out_time_until}
                  </span>
                </Typography>
              )}
            </Box>
            <Box>
              <Buttons text="Edit" btnClick={() => handleEditProperty(4)} />
            </Box>
          </Box>
        )}

        {/* Property Surroundings */}
        {productDetails?.property_surronding &&
          productDetails?.property_surronding.length > 0 && (
            <Box
              sx={{
                mb: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                }}
              >
                <Typography
                  fontWeight={600}
                  sx={{
                    color: "Black",
                    mb: ".5rem",
                    fontSize: "1.2rem",
                  }}
                >
                  Hotel Surroundings
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: ".5rem",
                    display: "flex",
                    justifyContent: {
                      xs: "space-between",
                      sm: "space-around",
                      md: "space-around",
                    },
                  }}
                >
                  <Box sx={{ width: { xs: "47%", sm: "45%", md: "40%" } }}>
                    {productDetails?.property_surronding &&
                      firstHalf?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: {
                              xs: ".5rem",
                              sm: ".3rem",
                              md: ".3rem",
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={{ xs: 12, sm: 14, md: 16 }}
                          >
                            {ele?.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={{ xs: 10, sm: 12, md: 14 }}
                          >
                            {ele?.distance}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ width: { xs: "47%", sm: "45%", md: "40%" } }}>
                    {productDetails?.property_surronding &&
                      secondHalf?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: {
                              xs: ".5rem",
                              sm: ".3rem",
                              md: ".3rem",
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={{ xs: 12, sm: 14, md: 16 }}
                          >
                            {ele?.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={{ xs: 10, sm: 12, md: 14 }}
                          >
                            {ele?.distance}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
              <Box>
                {/* <Buttons text="Edit" btnClick={() => handleEditProperty(8)} /> */}
              </Box>
            </Box>
          )}

        {/* Property Calendar */}
        <Box
          sx={{ mb: "1rem", display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{ fontSize: "1.25rem", color: "black.main" }}
            >
              Your calendar
            </Typography>
            <Box>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                selectable={false}
                // onSelectSlot={handleSelect}
                // onSelectEvent={(event) => handleDeleteEvent(event)}
                style={{ height: 500, padding: "1rem 0rem 4.5rem 0rem" }}
                dayPropGetter={dayPropGetter}
              />
            </Box>
          </Box>
          <Box>
            <Buttons text="Edit" btnClick={() => handleEditProperty(10)} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Edit;

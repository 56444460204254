import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import MobileView from "./pages/MobileView"
import Edit from "./pages/Edit"
import EditProperty from "./pages/EditProperty"

export const routes = [
    {
        path:'/login',
        component:<Login />
    },
    {
        path:'/',
        component:<Dashboard />
    },
    {
        path:'/mobile',
        component:<MobileView />
    },
    {
        path:'/edit',
        component:<Edit />
    },
    {
        path:'/edit-property',
        component:<EditProperty />
    },
]
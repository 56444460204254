import React from "react";
import { useSwiper } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";

const SwiperNextButton = () => {
  const swiper = useSwiper();
  return (
    <Box
      onClick={() => swiper.slideNext()}
      sx={{
        position: "absolute",
        zIndex: 500,
        bottom: "50%",
        transform:"translate(0%, 50%)",
        right: "2%",
        borderRadius: "50%",
        width: "2.5rem",
        height: "2.5rem",
        // backgroundColor: "red",
        display: { xs: "none", sm: "flex" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        style={{
          cursor:"pointer",
          border: "none",
          borderRadius: "50%",
          width: "2.5rem",
          height: "2.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#E87B25",
        }}
        aria-label="nextButton"
        className="nextButton"
      >
        <ArrowForwardIcon sx={{ color: "white.main" }} />
      </button>
    </Box>
  );
};
// erjn
export default SwiperNextButton;
